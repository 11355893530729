import React from 'react';
import { v4 } from 'uuid';

// eslint-disable-next-line react/prefer-stateless-function
export default class RoundGroup extends React.Component {
  render() {
    const { scoringModeType, scoringModeIsNet } = this.props;

    return (
      <section className="leaderboard">
        <div className="container">
          <ol className="players">
            {this.props.orderedRounds
              .map((round, index) => (
                <Round
                  key={v4()}
                  round={round}
                  rank={index + 1}
                  scoringModeType={scoringModeType}
                  scoringModeIsNet={scoringModeIsNet}
                  openState={this.props.openStates.get(round.id)}
                  openOrCloseCell={this.props.openOrCloseCell}
                />
              ))}
          </ol>
        </div>
      </section>
    );
  }
}

class Round extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameExpanded: false,
    };
  }

  toggleState = (key) => () => { this.setState((prevState) => ({ [key]: !prevState[key] })); };

  render() {
    const {
      round, rank, scoringModeType, scoringModeIsNet, openState, openOrCloseCell,
    } = this.props;
    const { tee, holes } = round;
    const scoreValues = round.scoresByScoringMode(scoringModeType, scoringModeIsNet);
    const userName = round.user.fullName;

    return (
      <li className={`player-cell ${openState ? null : 'closed'}`} onClick={() => { openOrCloseCell(round.id); }}>
        <div className="details">
          <p className="rank caption-1-bold">{rank}</p>

          <div className="avatar-container">
            <img className="avatar-40 round" role="presentation" src={round.user.avatarUrl} alt={userName} />
            <p className="player-tee text-upper" style={{ backgroundColor: tee.backgroundColor, color: tee.textColor }}>{tee.name}</p>
          </div>

          <div className="player-info">
            <p className={`name body-bold ${userName.length > 10 ? 'large-name' : ''}`}>{userName}</p>

            {userName.length > 10
              && (
              <div className={`expand-name ${this.state.nameExpanded ? 'visible' : null}`}>
                <span className="action" onClick={this.toggleState('nameExpanded')} />
                <p className="tooltip caption-1-bold" onClick={this.toggleState('nameExpanded')}>{userName}</p>
              </div>
              )}

            <p className="handicap subhead text-light">{round.user.subtitle}</p>
          </div>
        </div>

        <div className="score">
          {holes.length > 0
            && (
            <div className="scrollable">
              <div className="holes-details">
                <div className="holes">
                  <p className="caption-2-bold text-light text-upper">Holes</p>

                  <div className={`holes-list ${holes.length}`}>
                    {holes.map((hole) => <p key={hole.sequence} className="hole caption-2-bold text-light">{hole.sequence}</p>)}
                  </div>
                </div>

                <div className="par">
                  <p className="caption-2-bold text-light text-upper">PAR</p>

                  <div className={`par-list ${holes.length}`}>
                    {holes.map((hole) => <p key={hole.sequence} className="hole caption-2 text-lighter">{hole.par}</p>)}
                  </div>
                </div>

                <div className="si">
                  <p className="caption-2-bold text-light text-upper">S.I.</p>

                  <div className={`si-list ${holes.length}`}>
                    {holes.map((hole) => <p key={hole.sequence} className="hole caption-2 text-lighter">{hole.strokeIndex}</p>)}
                  </div>
                </div>
              </div>

              <div className="holes-scores">
                <p className="section-label caption-2-bold text-light text-upper">
                  {holes.length > 0 && 'Score'}
                </p>

                <div className="scores">
                  {holes.map((hole, index) => (
                    <div key={index} className={`hole-score score-${hole.scoreColorName}`}>
                      <p className={`value body-bold ${Optional(hole.isScratched).map(() => 'scratched')}`}>
                        {hole.totalOfStrokes || '–'}
                      </p>

                      <span className="hole-points caption-2 text-light">{hole.stablefordScore(scoringModeType, scoringModeIsNet)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            )}

          <div className="additional">
            {holes.length > 0
              && (
              <div className="labels">
                <p className="caption-2-bold text-light">{scoreValues.score_title}</p>
                <p className="caption-2-bold text-light" data-content={scoreValues.to_par_title.toLowerCase().replace(/\s/g, '-')}><span>{scoreValues.to_par_title}</span></p>
                <p className="caption-2-bold text-light">THRU</p>
              </div>
              )}

            <div className="values">
              <p className="body-bold">{scoreValues.total_score}</p>
              <p className="body-bold">{scoreValues.to_par}</p>
              <p className="body-bold">{round.throughHole}</p>
            </div>
          </div>
        </div>

        {holes.length > 0 && <button type="button" className="expand-cell" />}
      </li>
    );
  }
}
