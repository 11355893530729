import React from 'react';

function StoryblokBridge() { // receives storyblok data as props
  return (
    <div>
      <h1> Storyblok Bridge </h1>
    </div>
  );
}

export default StoryblokBridge;
