import React from 'react';
import RoundGroupViewModel from './RoundGroupViewModel.es6';

import RoundGroup from './RoundGroup';

class RoundGroupShow extends React.Component {
  constructor(props) {
    super(props);

    this.polling_interval_in_seconds = props.polling_interval_in_seconds;

    const viewModel = RoundGroupViewModel(props.data, props.default_avatar);

    this.state = {
      viewModel,
      openStates: new Map(viewModel.rounds.map((round) => [round.id, false])),
      scoringModeType: viewModel.scoringModeType,
      scoringModeIsNet: viewModel.scoringModeIsNet,
    };

    this.intervalId = null;
  }

  componentDidMount() {
    this.intervalId = setInterval(this.fetchData, this.polling_interval_in_seconds * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  fetchData = async () => {
    const response = await fetch(`${window.location.href}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    if (response.ok) {
      const result = await response.json();
      const viewModel = RoundGroupViewModel(result, this.props.default_avatar);

      const { openStates } = this.state;
      const newOpenStates = new Map(
        viewModel.rounds.map((round) => [round.id, openStates.get(round.id) || false]),
      );

      if (viewModel.status === 'closed') {
        clearInterval(this.intervalId);
      }

      this.setState({ viewModel, openStates: newOpenStates });

      return;
    }

    if (response.status === 404) {
      window.location.reload(false);
    } else {
      console.error('There has been a problem with your fetch operation:', response.status);
    }
  };

  didChangeScoringModeType = () => (e) => {
    const validScoringModes = ['stroke_play', 'stableford'];
    const newScoringModeType = `${e.target.value}`;
    if (!validScoringModes.includes(newScoringModeType)) return;
    this.updateScoringMode(newScoringModeType, this.state.viewModel.defaultScoringModeIsNet(newScoringModeType));
  };

  didChangeScoringModeIsNet = () => {
    this.updateScoringMode(this.state.scoringModeType, !this.state.scoringModeIsNet);
  }

  updateScoringMode = (scoringModeType, scoringModeIsNet) => {
    this.setState(
      {
        scoringModeType,
        scoringModeIsNet,
      },
    );
  }

  openOrCloseCell = (roundId) => {
    const { openStates } = this.state;
    openStates.set(roundId, !openStates.get(roundId));
    this.setState({ openStates });
  }

  render() {
    const { viewModel, scoringModeType, scoringModeIsNet } = this.state;
    const { course } = viewModel;

    return (
      <main className="page-leaderboard">
        <header className={`header-leaderboard ${this.props.in_round ? 'in-round' : ''}`}>
          <div className="container">
            <div className="content">
              <div className="left-info">
                <p className="event-date subhead text-light">
                  {viewModel.startedAt.toDateString()}
                  {viewModel.isLive() ? <span className="label label-green">Live</span> : null}
                </p>

                <h1 className="event-name title-2">
                  {course.name}
                </h1>
              </div>
              <div className="right-actions">
                <div className="scoring-mode-selector">
                  <select
                    onChange={this.didChangeScoringModeType()}
                    value={scoringModeType}
                    className="dropdown-2-compact"
                  >
                    <option value="stroke_play">Stroke Play</option>
                    <option value="stableford">Stableford</option>
                  </select>
                  <label className="net-checkbox">
                    <input
                      type="checkbox"
                      checked={scoringModeIsNet}
                      onChange={this.didChangeScoringModeIsNet}
                    />
                    <span>Net</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </header>

        <RoundGroup
          orderedRounds={viewModel.roundsSortedByScoringModeTypeAndNet(scoringModeType, scoringModeIsNet)}
          openStates={this.state.openStates}
          openOrCloseCell={this.openOrCloseCell}
          scoringModeType={scoringModeType}
          scoringModeIsNet={scoringModeIsNet}
        />
      </main>
    );
  }
}

export default RoundGroupShow;
